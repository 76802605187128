import { t } from '@lingui/macro'

/** 排名表格模块 */
export enum RankingListTableTypeEnum {
  /** 我的投注 */
  own = 'own',
  /** 所有投注 */
  all = 'all',
  /** 风云榜 */
  billboard = 'billboard',
  /** 竞赛排行榜 */
  competition = 'competition',
}

export const getRankingListTableTypeName = type => {
  return {
    [RankingListTableTypeEnum.own]: t`features_home_my_bet_index_avwmsadjdl`,
    [RankingListTableTypeEnum.all]: t`features_home_component_ranking_list_table_index_q2t7unlgki`,
    [RankingListTableTypeEnum.billboard]: t`features_home_component_ranking_list_table_index_mhm5a2mgzp`,
    [RankingListTableTypeEnum.competition]: t`features_home_component_ranking_list_table_index_ydzivz0sfi`,
  }[type]
}

/** 游戏功能按钮枚举 */
export enum GameSettingTypeEnum {
  /** 分享 */
  share = 'share',
  /** 收藏 */
  collect = 'collect',
  /** 剧场模式 */
  theaterMode = 'theaterMode',
  /** 全屏 */
  fullScreen = 'fullScreen',
  /** 返回 */
  return = 'return',
}

export const getGameButtonName = type => {
  return {
    [GameSettingTypeEnum.share]: t`features_marketing_fission_activity_index_hry8jdhh7y`,
    [GameSettingTypeEnum.collect]: t`features_home_game_details_component_game_screen_index_ak0kbkmgkv`,
    [GameSettingTypeEnum.theaterMode]: t`features_home_game_details_component_game_screen_index_nmacjfwfa9`,
    [GameSettingTypeEnum.fullScreen]: t`features_home_game_details_component_game_screen_index_26eggfvdvq`,
    [GameSettingTypeEnum.return]: t`user.field.reuse_44`,
  }[type]
}

export const getGameButtonIcon = type => {
  return {
    [GameSettingTypeEnum.share]: 'icon_healing_share',
    [GameSettingTypeEnum.collect]: 'icon_collect_sidebar_pre_v2',
    [GameSettingTypeEnum.theaterMode]: 'icon_theater_mode_v2',
    [GameSettingTypeEnum.fullScreen]: 'icon_full_screen_v2',
    [GameSettingTypeEnum.return]: 'nav_icon_delete_v2',
  }[type]
}

export enum GameContactUsEnum {
  /** 联系我们显示参数 */
  isWeb = 1,
}

/** 底部联系我们枚举 */
export enum LinkValEnum {
  /**  email */
  emailCustomer = 'emailCustomer',
  /** whatsapp */
  whatsLink = 'whatsLink',
}

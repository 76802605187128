import { useEffect } from 'react'
import classNames from 'classnames'
import { Icon, Tabs } from '@nbit/arco'
import { alertStealthTip } from '@/components/stealth-tip'
import Icons from '@/components/icon'
import { useRaSettingsStore } from '@/store/settings'
import { t } from '@lingui/macro'
import { useRequest } from 'ahooks'
import { PrivacySettingEnum } from '@/constants/settings'
import { postRaHallUserPreference, getRaHallUserPreference } from '@/apis/entertainment-area'
import { useUserStore } from '@/store/user'
import { RankingTableType } from '@/typings/common'

import styles from './index.module.css'

export interface IRecordTabsProps {
  className?: string
  /** 是否展示 tab 下对应 DOM */
  isShowTabContent?: boolean
  value: string
  tabs: RankingTableType[]
  /** 是否展示隐身按钮 */
  isShowStealth?: boolean
  onChange?: (v: string) => void
}

export default function CommonRecordTabs(props: IRecordTabsProps) {
  const { value, tabs, className, isShowTabContent = true, isShowStealth = true, onChange } = props || {}

  const { isLogin } = useUserStore() // 是否登录
  const { raSettings, updateRaSettings } = useRaSettingsStore() // 隐身模式状态
  // get 隐身配置请求
  let getStealth = useRequest(
    async () => {
      const res = await getRaHallUserPreference({})
      if (res.isOk && res.data) {
        // 更新 store
        setaStealthSucces(res.data)
      }
    },
    {
      manual: true,
    }
  )
  // 设置隐身模式成功后调用
  function setaStealthSucces(
    data = {
      ...raSettings,
      hideStatus: raSettings.hideStatus === 'disable' ? 'enable' : 'disable',
    }
  ) {
    // 过滤 data 里为 null || undeifine 的数据
    const dataFilter = Object.keys(data).reduce(
      (acc, cur) => (data[cur] === null || data[cur] === undefined ? acc : { ...acc, [cur]: data[cur] }),
      {}
    )
    // 更新存储
    updateRaSettings({
      ...raSettings,
      ...dataFilter,
    })
  }
  // 显示隐身模式弹窗
  function showStealModelTip() {
    alertStealthTip({
      title: t`features_home_component_record_tabs_index_pil0u_f8b9`,
      content: `${t`features_home_component_record_tabs_index__whykymamr`}${
        raSettings.hideStatus === 'disable'
          ? t`features_home_component_record_tabs_index_aas8bnpjx9`
          : t`features_home_component_record_tabs_index_fghkbzokp9`
      }`,
    })
  }
  // 隐身模式按钮点击，弹窗逻辑
  const onStealTip = async () => {
    postStealth.run()
  }
  // 页面上隐身按钮 dom
  function StealthBtn() {
    return (
      <div className="stealth-btn" onClick={onStealTip}>
        <Icons name="icon_hide_v2" />
        <div>
          {`${t`features_home_component_record_tabs_index_pil0u_f8b9`}${
            raSettings.hideStatus === PrivacySettingEnum.disable
              ? t`features_home_component_record_tabs_index_yhdkdyhueg`
              : t`features_home_component_record_tabs_index_xknyx6jkr3`
          }`}
        </div>
      </div>
    )
  }
  // 发送请求保存配置
  const postStealth = useRequest(
    async () => {
      const res = await postRaHallUserPreference({
        ...raSettings,
        hideStatus: raSettings.hideStatus === 'disable' ? 'enable' : 'disable',
      })
      if (res.isOk && res.data) {
        getStealth.run()
        showStealModelTip()
      }
    },
    {
      throttleWait: 300,
      manual: true,
    }
  )

  const onRenderTabContent = () => {
    return tabs?.filter(tab => tab?.id === value)?.[0]?.content || null
  }

  const onTabsChange = v => {
    onChange?.(v)
  }

  useEffect(() => {
    // 登录才获取配置
    isLogin && getStealth.run()
  }, [isLogin])

  return (
    <div className={classNames(styles['record-tabs-root'], className)}>
      <div className="tabs">
        <Tabs
          activeTab={value}
          direction="horizontal"
          className="tabs-wrap"
          onChange={onTabsChange}
          scrollPosition="auto"
          // icons={{
          //   prev: <Icon name="icon_arrow_previous" className="tabs-arrow" />,
          //   next: <Icon name="icon_arrow_next" className="tabs-arrow" />,
          // }}
        >
          {tabs?.map((tab, i) => (
            <Tabs.TabPane
              disabled={tab?.disabled}
              destroyOnHide
              key={tab.id}
              title={
                <div
                  className={classNames('tab-cell', {
                    active: value === tab?.id,
                    disabled: tab?.disabled,
                  })}
                >
                  {tab.name}
                </div>
              }
            />
          ))}
        </Tabs>
        {/* 隐藏隐身功能 */}
        {isLogin && isShowStealth ? <StealthBtn /> : null}
      </div>
      {isShowTabContent && onRenderTabContent()}
    </div>
  )
}

import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import { IRecordTabsProps } from '@/features/recreation/component/record-tabs'
import RecordTabs2z from '@/features/recreation/theme/2z/record-tabs'
import RecordTabsOk from '@/features/recreation/theme/ok/record-tabs'

export default function RecordTabs(props: IRecordTabsProps) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <RecordTabs2z {...props} />
    case InitThemeColor.ok:
      return <RecordTabsOk {...props} />
    default:
      return <RecordTabs2z {...props} />
  }
}
